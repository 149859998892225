<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99331 1.3335C4.31331 1.3335 1.33331 4.32016 1.33331 8.00016C1.33331 11.6802 4.31331 14.6668 7.99331 14.6668C11.68 14.6668 14.6666 11.6802 14.6666 8.00016C14.6666 4.32016 11.68 1.3335 7.99331 1.3335ZM7.99998 13.3335C5.05331 13.3335 2.66665 10.9468 2.66665 8.00016C2.66665 5.0535 5.05331 2.66683 7.99998 2.66683C10.9466 2.66683 13.3333 5.0535 13.3333 8.00016C13.3333 10.9468 10.9466 13.3335 7.99998 13.3335ZM7.85331 4.66683H7.81331C7.54665 4.66683 7.33331 4.88016 7.33331 5.14683V8.2935C7.33331 8.52683 7.45331 8.74683 7.65998 8.86683L10.4266 10.5268C10.6533 10.6602 10.9466 10.5935 11.08 10.3668C11.22 10.1402 11.1466 9.84016 10.9133 9.70683L8.33331 8.1735V5.14683C8.33331 4.88016 8.11998 4.66683 7.85331 4.66683Z"
      fill="#292F33"
      fill-opacity="0.4"
    />
  </svg>
</template>
