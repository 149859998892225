<template>
  <div class="RoomTile__container" :class="variantClass">
    <div
      class="RoomTile__wrapper"
      :class="{ expired: status === 'expired' }"
      @click="handleTileClick"
    >
      <div class="RoomTile__heading">
        <div class="RoomTile__heading-left" :class="variantClass">
          <component
            :is="iconComponent"
            v-bind="{ expired: status === 'expired' }"
          />
        </div>
        <div class="RoomTile__heading-right">
          <button
            v-if="
              isDashboardVariant &&
              (status === 'ready' || status === 'ready_to_renew')
            "
            class="RoomTile__heading-button"
            @click.stop="handleMoreClick"
          >
            <o-icon icon="dots-vertical" />
          </button>
          <span v-if="status === 'expired'" class="RoomTile__heading-expired"
            >Expired</span
          >
          <RoomLoader
            v-if="status === 'processing'"
            class="RoomTile__loader-wrapper"
          />
        </div>
      </div>
      <h4 class="RoomTile__name">
        {{ name }}
      </h4>
      <div
        v-if="isDashboardVariant && status === 'ready'"
        class="RoomTile__info"
      >
        <span class="RoomTile__participants"
          >{{ currentParticipants }}/{{ max_participants }}</span
        >
        <span
          v-if="roomLabel"
          class="RoomTile__label"
          :class="roomLabel.class"
          >{{ roomLabel.text }}</span
        >
      </div>
      <span v-if="status === 'processing'" class="RoomTile__label not-ready">
        Room is being prepared
      </span>
      <span
        v-if="
          roomLabel &&
          !isDashboardVariant &&
          !isSessionActive &&
          status === 'ready'
        "
        class="RoomTile__label small"
        :class="roomLabel.class"
        >{{ roomLabel.text }}</span
      >
      <span
        v-if="!isDashboardVariant && !isSessionActive && status !== 'expired'"
        class="RoomTile__participants small"
        >{{ currentParticipants }}/{{ max_participants }}</span
      >
      <span
        v-if="roomDescription && isDashboardVariant && !isSessionActive"
        class="RoomTile__description"
        :class="{ expired: status === 'expired' }"
        >{{ roomDescription }}</span
      >
      <div
        v-if="!!session && session.participants_count > 0"
        class="RoomTile__avatar-group"
      >
        <CommonAvatarGroup
          :participants-count="session.participants_count"
          :users-profiles="session.users_profiles"
        />
      </div>
      <div v-if="status === 'expired'" class="RoomTile__buttons-wrapper">
        <button class="RoomTile__button-renew" @click.stop="handleRenewClick">
          Renew
        </button>
        <button
          v-if="isDashboardVariant"
          class="RoomTile__button-remove"
          @click.stop="handleRemoveClick"
        >
          <o-icon icon="minus-circle-outline" custom-size="mdi-24px" />Remove
        </button>
      </div>
    </div>
    <div v-if="expireWarning.show" class="RoomTile__expires-wrapper">
      <span class="RoomTile__expires-text">{{ expireWarning.text }}</span>
      <button class="RoomTile__expires-button" @click="handleRenewClick">
        Renew
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { DateTime } from "luxon";
import { Room, ActiveSession } from "~/types";

type Props = Room & {
  session: ActiveSession | null;
  variant?: "DASHBOARD" | "INSTANT-MEETING";
};

const props = withDefaults(defineProps<Props>(), {
  variant: "DASHBOARD",
});

const emit = defineEmits<{
  (e: "tile-click", id: string): void;
  (e: "renew-click", id: string): void;
  (e: "remove-click", id: string): void;
  (e: "more-click", id: string): void;
}>();

const iconAudio = resolveComponent("DashboardIconAudio");
const iconVideo = resolveComponent("DashboardIconVideo");
const iconStreamed = resolveComponent("DashboardIconStreamed");

const iconComponent = computed(() => {
  switch (props.room_type) {
    case "audio":
      return iconAudio;
    case "video":
      return iconVideo;
    case "streamed":
      return iconStreamed;
  }
});

const roomExpirationDateTime = computed(() => {
  return DateTime.fromISO(props.expiration_datetime, {
    zone: "utc",
  }).toLocal();
});

const expireWarning = computed(() => {
  if (props.status === "ready_to_renew") {
    return {
      text: `Expires ${roomExpirationDateTime.value.toRelativeCalendar({ unit: "days" })}`,
      show: true,
    } as const;
  }
  return {
    show: false,
  } as const;
});

const roomLabel = computed(() => {
  if (props.max_participants === 10) {
    return { text: "Free", class: "primary" } as const;
  }
  if (props.subscription_limit_in_seconds) {
    const limit =
      Number(props.subscription_limit_in_seconds) -
      Number(props.subscription_limit_used_in_seconds);
    const limitInMins = limit / 60;

    const durationText = `${Math.floor(limitInMins / 60)}h${Math.floor(limitInMins % 60) !== 0 ? ` ${Math.floor(limitInMins % 60)}m` : ""}`;

    return {
      text: `${durationText} left`,
      class: "primary",
    };
  }
  return null;
});

const roomDescription = computed(() => {
  if (props.status === "processing") {
    return "It will be ready in a few minutes";
  }
  if (props.status === "expired") {
    return "";
  }

  switch (props.room_type) {
    case "audio":
      return "Start quick audio meeting instantly";
    case "video":
      return "Start quick video meeting instantly";
    case "streamed":
      return "";
    default:
      return "";
  }
});

const isDashboardVariant = computed(() => {
  return props.variant === "DASHBOARD";
});

const isSessionActive = computed(() => {
  return !!props.session && props.session.participants_count > 0;
});

const currentParticipants = computed(() => {
  if (props.session) {
    return props.session.participants_count;
  }
  return 0;
});

const variantClass = computed(() => {
  const className = [];
  switch (props.variant) {
    case "DASHBOARD":
      break;
    case "INSTANT-MEETING":
      className.push("small");
      break;
  }

  if (props.status === "expired") {
    className.push("expired");
    return className.join(" ");
  }

  if (isSessionActive.value) {
    switch (props.room_type) {
      case "video":
        className.push("active-primary");
        break;
      case "audio":
        className.push("active-tertiary");
        break;
      case "streamed":
        className.push("active-secondary");
        break;
    }
  }
  return className.join(" ");
});

const handleRenewClick = () => {
  emit("renew-click", props.id);
};

const handleRemoveClick = () => {
  emit("remove-click", props.id);
};

const handleMoreClick = () => {
  emit("more-click", props.id);
};

const handleTileClick = () => {
  if (props.status === "ready" || props.status === "ready_to_renew") {
    emit("tile-click", props.id);
  }
};
</script>
<style scoped lang="scss">
.RoomTile {
  &__container {
    width: 100%;
    height: 235px;
    background: $color-neutral-2;
    min-width: 154px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    &.small {
      height: 183px;
    }
    &.expired {
      background: $color-neutral-4;
    }
    &.active {
      &-primary {
        border: 2px solid $color-primary;
      }
      &-secondary {
        border: 2px solid $color-secondary;
      }
      &-tertiary {
        border: 2px solid $color-tertiary;
      }
    }
    @include sm {
      max-width: 320px;
    }
  }
  &__wrapper {
    padding: 12px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    flex: 1;
    cursor: pointer;
    &.expired {
      cursor: initial;
    }
  }
  &__heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &__heading-left,
  &__heading-right {
    display: flex;
    flex-direction: column;
  }
  &__heading-left {
    &.small {
      svg {
        width: 28px;
        height: 28px;
      }
    }
  }
  &__heading-button {
    background: none;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0;
    width: 24px;
    height: 24px;
  }
  &__heading-expired {
    @include label-m;
    color: $color-element-dark-medium;
  }
  &__loader-wrapper {
    width: 22px;
    height: 22px;
  }
  &__name {
    @include header-xs-bold;
    color: $color-element-dark-strong;
    margin-top: 12px;
    margin-bottom: 4px;
  }
  &__info {
    display: flex;
    flex-direction: row;
  }
  &__participants {
    @include label-m;
    color: $color-element-dark-basic;
    margin-right: 6px;
    &.small {
      margin-right: 0;
      margin-top: auto;
    }
  }
  &__label {
    @include label-m;
    color: $color-element-dark-disabled;
    padding-left: 6px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 10px;
      background: $color-element-dark-disabled;
    }
    &.primary {
      color: $color-primary;
    }
    &.not-ready {
      color: $color-primary;
      padding: 0;
      &::after {
        content: unset;
      }
    }
    &.small {
      padding: 0;
      &::after {
        content: unset;
      }
    }
  }
  &__description {
    @include body-s;
    color: $color-element-dark-basic;
    margin-top: auto;
    text-wrap: balance;
    &.expired {
      margin-top: unset;
    }
  }
  &__avatar-group {
    margin-top: auto;
  }
  &__expires-wrapper {
    padding: 12px;
    background: $color-basic-error-background;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 8px;
  }
  &__expires-text {
    @include label-m;
    color: $color-basic-error;
  }
  &__expires-button {
    @include label-m;
    color: $color-basic-error;
    padding: 0;
    border: none;
    background: none;
    text-decoration: underline;
    cursor: pointer;
  }
  &__buttons-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    margin-top: auto;
  }
  &__button-renew {
    @include label-l;
    border: none;
    background: $color-primary;
    color: $color-element-light-strong;
    border-radius: 999px;
    padding: 7px;
    cursor: pointer;
  }
  &__button-remove {
    @include label-l;
    border: none;
    background: none;
    color: $color-basic-error;
    padding: 7px;
    display: flex;
    flex-direction: row;
    column-gap: 6px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
</style>
