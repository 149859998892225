<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.2217 15.2682L16.6817 14.9782C16.0717 14.9082 15.4717 15.1182 15.0417 15.5482L13.2017 17.3882C10.3717 15.9482 8.05174 13.6382 6.61174 10.7982L8.46174 8.94819C8.89174 8.51819 9.10174 7.91819 9.03174 7.30819L8.74174 4.78819C8.62174 3.77819 7.77174 3.01819 6.75174 3.01819H5.02174C3.89174 3.01819 2.95174 3.95819 3.02174 5.08819C3.55174 13.6282 10.3817 20.4482 18.9117 20.9782C20.0417 21.0482 20.9817 20.1082 20.9817 18.9782V17.2482C20.9917 16.2382 20.2317 15.3882 19.2217 15.2682Z"
      fill="#46CCEA"
    />
  </svg>
</template>
