<template>
  <div class="CalendarEventTile__wrapper" @click="handleTileClick">
    <button
      class="CalendarEventTile__more-button"
      @click.stop="handleMoreClick"
    >
      <o-icon icon="dots-vertical" />
    </button>
    <span class="CalendarEventTile__time-to-start" :class="timeToStart.class">{{
      timeToStart.text
    }}</span>
    <h3 class="CalendarEventTile__name">{{ name }}</h3>
    <div
      class="CalendarEventTile__info"
      :class="{ 'mb-0': timeToStart.enableJoin }"
    >
      <DashboardIconClock />
      <span class="CalendarEventTile__time">{{ timeText }}</span>
      <component :is="iconComponent" />
      <span class="CalendarEventTile__room-type" :class="roomTypeClassName">{{
        roomName
      }}</span>
    </div>
    <div class="CalendarEventTile__avatar-wrapper">
      <div class="CalendarEventTile__owner">
        <Avatar :data="avatarProps" /> {{ owner.name }}
      </div>
      <button
        v-if="timeToStart.enableJoin"
        class="CalendarEventTile__join-button"
        @click.stop="handleJoinClick"
      >
        Join Now
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { DateTime, Interval } from "luxon";
import { RoomType } from "~/types";

type MeetingOwner = {
  name: string;
  username: string;
  avatarUrl?: string | null;
};

type Props = {
  id: string;
  name: string;
  startDateTime: string;
  endDateTime: string;
  roomPrivateUrl: string;
  roomName: string;
  roomType: RoomType;
  owner: MeetingOwner;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "more-click", id: string): void;
  (e: "tile-click", id: string): void;
}>();

const handleMoreClick = () => {
  emit("more-click", props.id);
};

const handleTileClick = () => {
  emit("tile-click", props.id);
};

const iconVideo = resolveComponent("DashboardIconCalendarEventVideo");
const iconAudio = resolveComponent("DashboardIconCalendarEventAudio");
const iconStreamed = resolveComponent("DashboardIconCalendarEventStreamed");

const iconComponent = computed(() => {
  switch (props.roomType) {
    case "video":
      return iconVideo;
    case "audio":
      return iconAudio;
    case "streamed":
      return iconStreamed;
  }
});

const timeToStart = computed(() => {
  const date = DateTime.fromISO(props.startDateTime, { zone: "utc" }).toLocal();
  const now = DateTime.now().toLocal();
  const diff = Interval.fromDateTimes(now, date);

  if (date < now) {
    return { text: "Live", class: "alert", enableJoin: true } as const;
  }

  if (diff.length("minutes") < 3) {
    return { text: "Live", class: "alert", enableJoin: true } as const;
  }
  if (diff.length("minutes") < 60) {
    return {
      text: `Starts in ${diff.length("minutes").toFixed()} min`,
      class: "alert",
      enableJoin: false,
    } as const;
  }
  if (diff.length("hours") < 1.5) {
    return {
      text: `Starts in ${diff.length("hours").toFixed()} hour`,
      class: "alert",
      enableJoin: false,
    } as const;
  }
  if (
    date.hasSame(now, "day") &&
    date.hasSame(now, "month") &&
    date.hasSame(now, "year") &&
    diff.length("hours") < 24
  ) {
    return {
      text: `Starts in ${diff.length("hours").toFixed()} hours`,
      class: "info",
      enableJoin: false,
    } as const;
  }
  return {
    text: `Starts ${date.setLocale("en-US").toRelativeCalendar()}`,
    class: "info",
    enableJoin: false,
  } as const;
});

const timeText = computed(() => {
  const zoneName = DateTime.local().zoneName;
  const start = DateTime.fromISO(props.startDateTime, {
    zone: "utc",
  }).toLocal();
  const end = DateTime.fromISO(props.endDateTime, { zone: "utc" }).toLocal();
  const localeOptions = {
    locale: DateTime.local().locale || "en-GB",
  };
  if (zoneName === "Africa/Lagos") {
    localeOptions.locale = "en-US";
  }
  return `${start.toLocaleString(
    DateTime.TIME_SIMPLE,
    localeOptions,
  )} - ${end.toLocaleString(DateTime.TIME_SIMPLE, localeOptions)}`;
});

const avatarProps = computed(() => {
  if (props.owner.avatarUrl && props.owner.avatarUrl.length > 0) {
    return {
      variant: "IMAGE",
      size: "24px",
      imageUrl: props.owner.avatarUrl,
    } as const;
  }
  return {
    variant: "USERNAME",
    size: "24px",
    username: props.owner.username,
  } as const;
});

const roomTypeClassName = computed(() => {
  switch (props.roomType) {
    case "video":
      return "primary";
    case "audio":
      return "tertiary";
    case "streamed":
      return "secondary";
  }
});

const handleJoinClick = () => {
  const roomUrl = `${window.origin}/room/${props.roomPrivateUrl}`;
  return navigateTo(roomUrl, { external: true });
};
</script>
<style scoped lang="scss">
.CalendarEventTile {
  &__wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 12px 16px 16px 16px;
    border: 1px solid $color-neutral-3;
    border-radius: 12px;
    cursor: pointer;
  }
  &__more-button {
    width: 24px;
    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    background: none;
    position: absolute;
    right: 16px;
    top: 12px;
    cursor: pointer;
  }
  &__time-to-start {
    @include label-m;
    &.alert {
      color: $color-basic-error;
    }
    &.info {
      color: $color-element-dark-medium;
    }
  }
  &__name {
    @include TitleMedium;
    color: $color-element-dark-strong;
    margin-top: 2px;
    margin-bottom: 12px;
  }
  &__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
    @include md {
      &.mb-0 {
        margin-bottom: 0;
      }
    }
  }
  &__time {
    @include label-m;
    color: $color-element-dark-basic;
    margin-left: 4px;
    padding-right: 18px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 8px;
      width: 2px;
      height: 100%;
      background: $color-element-dark-weak;
      border-radius: 100px;
    }
  }
  &__room-type {
    @include label-m;
    margin-left: 4px;
    &.primary {
      color: $color-primary;
    }
    &.secondary {
      color: $color-secondary;
    }
    &.tertiary {
      color: $color-tertiary;
    }
  }
  &__owner {
    @include label-m;
    color: $color-element-dark-basic;
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
  }
  &__join-button {
    @include button-primary;
    text-decoration: none;
    text-align: center;
    margin-top: 12px;
    @include md {
      margin-top: 0;
      width: 120px;
    }
  }
  &__avatar-wrapper {
    display: flex;
    flex-direction: column;
    @include md {
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
    }
  }
}
</style>
